@import '~theme/variables';

.image {
  border-radius: $border-radius-03;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
  transition: filter $transition-01;

  &:hover {
    filter: $hover-image-brightness;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  height: 100%;

  @include breakpoint(sm) {
    flex-direction: column;
  }
}

.controlWrapper {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  div {
    background-color: $color-neutral-05;
    border-radius: $border-radius-03;
    position: relative;
    text-align: left;
    overflow: hidden;
    aspect-ratio: 4/3;

    .image {
      position: absolute;
      width: 100%;
      aspect-ratio: 4/3;
    }
  }
}

.primaryImage {
  .image,
  div {
    aspect-ratio: 4/3;
  }
}

$timeout: 400ms;

// Image animation
.slide-right-enter {
  transform: translateX(100%);
}

.slide-right-enter-active {
  transform: translateX(0%);
  transition: transform $timeout ease-in-out;
}

.slide-right-exit {
  transform: translateX(0%);
}

.slide-right-exit-active {
  transform: translateX(-100%);
  transition: transform $timeout ease-in-out;
}

.slide-left-enter {
  transform: translateX(-100%);
}

.slide-left-enter-active {
  transform: translateX(0%);
  transition: transform $timeout ease-in-out;
}

.slide-left-exit {
  transform: translateX(0%);
}

.slide-left-exit-active {
  transform: translateX(100%);
  transition: transform $timeout ease-in-out;
}
.text-opacity-enter {
  opacity: 0;
}
.text-opacity-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}
.text-opacity-exit {
  display: none;
}
